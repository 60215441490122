import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Components",
  "sortOrder": 7
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Components`}</h1>
    <p>{`Caesars UI exports a suite of components to help both Web and iOS Native platforms develop faster and more efficiently.  Components are scope focused, tested and designer approved so you know they're design system compliant.   All of our components also pass the Web Content Accessibility Guidelines (WCAG 2.1 AA).  `}</p>
    <p>{`To view which components we're working on next, please visit our `}<a parentName="p" {...{
        "href": "/component-status"
      }}>{`Component Status Page`}</a></p>
    <p>{`To submit a component request, please reach out to us via our slack channel `}<inlineCode parentName="p">{`#caesars-ui`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      